@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.complete-sessions {
  $heightOfBottomBar: 80px;

  padding-bottom: calc(spacing.$xxxl + $heightOfBottomBar);

  &__header {
    padding: spacing.$l 0;
  }

  &__title {
    margin-bottom: spacing.$m;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: spacing.$l;
    }
  }

  &__groups {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__group {
    margin-bottom: spacing.$l;
  }

  &__group-title {
    margin-bottom: spacing.$s;
  }

  &__group-description {
    margin-bottom: spacing.$m;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    margin-bottom: spacing.$m;
    padding: 0;
  }

  &__list-title {
    margin-bottom: spacing.$xs;
  }

  &__submit-wrapper {
    position: fixed;
    bottom: 0;
    background-color: light.$surface-primary-default;
    border-top: 1px solid light.$ge-divider-default;
    text-align: center;
    width: 100%;
    padding: spacing.$m 0;
    z-index: 10;
  }
}
